@import "../../../common/colors.scss";

.skill-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
    margin-left: -25px;    
    margin-bottom: -10px;
    @media screen and (max-width: 915px) and (max-height: 550px) {
        margin-top: 20px;
    }
}

.tech-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    font-size: 90%;
    font-weight: bold;
    .icon {
        width: 39px;
        aspect-ratio: 1/1;
    }
    .faicon {
        width: 50px;
        aspect-ratio: 1/1;
    }

    filter: drop-shadow(3px 5px 2px $tetriary-color);

    @media screen and (max-width: 915px) and (max-height: 550px) {
        font-size: 30%;
        margin: 5px;
        .icon {
            display: none;
        }
        .faicon {
            display: none;
        }
    }
}

.tech-header {
    text-align: center;
    font-size: 15px;
    text-decoration: underline;
}



