@import "../../common/colors.scss";
@import "../../common/proportions.scss";
@import "../../common/buttons.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&family=Roboto+Condensed:wght@300&display=swap');

.projects-wrapper {
    min-height: calc(100vh - 82px);
    color: $font-primary;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.projects-info {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 1 !important;
    z-index: 1 !important;
    h1 {
        font-size: 64px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
        
        @media screen and (max-width: 767px) {
            font-size: 48px;
        }
        @media screen and (max-width: 420px) {
            font-size: 36px;
        }
    }
    h2 {
        font-size: 36px;
        font-weight: 400;
        color: $tetriary-color;
        text-align: center;
        @media screen and (max-width: 767px) {
            font-size: 24px;
        }
    }
}

.projects-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}