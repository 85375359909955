@import "../../common/colors.scss";
@import "../../common/proportions.scss";
@import "../../common/buttons.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&family=Roboto+Condensed:wght@300&display=swap');

.contact-me-wrapper {
    height: calc(100vh - 120px);
    color: $font-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contact-me-info {
    position: absolute;
    height: inherit;
    width: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    text-align: center;
    opacity: 1 !important;
    z-index: 1 !important;
    h1 {
        font-size: 64px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
        @media screen and (max-width: 767px) {
            font-size: 48px;
        }
        @media screen and (max-width: 420px) {
            font-size: 36px;
        }
    }
}

.content-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.form-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 15px 15px 15px;
    label {
        text-align: left;
        padding-left: 10px;
    }
    input {
        padding: 0 10px;
        border-radius: 10px;
    }
    .form-right {
        textarea {
            height: 80px;
            width: 210px;
            padding: 10px;
            border-radius: 10px;
            resize: none;
            @media screen and (min-width: 767px) {
                width: 500px;
            }
        }
    }
}

.form-left {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    justify-content: flex-end;
}

.form-right {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    justify-content: flex-end;
}