$primary-color: rgba(33, 37, 41, 1);
$secondary-color: #28104e;
$tetriary-color: #6237a0;
$font-primary: #ffffff;
$font-secondary: rgba(255, 255, 255, 0.55);
$hover-color: #590a8b;
$tetriary-transparent: rgb(89, 10, 91, 0.5);

.underline-decoration {
    margin: 15px auto 20px auto;
    background-color: $tetriary-color;
    height: 5px;
    border-radius: 20px 2px 20px 2px;
    width: 150px;
}