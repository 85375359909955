@import "../../common/colors.scss";
@import "../../common/proportions.scss";
@import "../../common/buttons.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&family=Roboto+Condensed:wght@300&display=swap');

.about-wrapper {
    height: 100vh;
    color: $font-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.about-info {
    position: absolute;
    height: inherit;
    width: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    text-align: center;
    opacity: 1 !important;
    z-index: 1 !important;
    h1 {
        font-size: 64px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
        @media screen and (max-width: 767px) {
            font-size: 48px;
        }
        @media screen and (max-width: 420px) {
            font-size: 36px;
        }
    }
    p {
        margin: -25px 0 -15px 0;
        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
        @media screen and (max-width: 420px) {
            font-size: 12px;
        } 
    }
}

.content-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        
    }
}

.about-left,
.about-right {
    max-width: 500px;
    padding: 15px;
    @media screen and (max-width: 915px) and (max-height: 550px) {
        padding: 0;
    }

}