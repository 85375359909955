@import "../../common/colors.scss";
@import "../../common/proportions.scss";
@import "../../common/buttons.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&family=Roboto+Condensed:wght@300&display=swap');

.wrapper {
    background-color: $hover-color;
    display: flex;
    width: 100%;
    height: 42px;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    position: relative;
}

span {
    font-size: 12px;
    color: white;
    text-align: center;
}

.footer-ul {
    color: white;
    margin: auto 0;
    display: flex;
    padding: 0 3px;
    border-radius: 10px;
    justify-content: center;
    list-style-type: none;
    font-size: 23px;
    background-color: black;

    li {
        padding: 0 5px;
        cursor: pointer;
        &:hover {
            color: $tetriary-color;
        }
        &:active {
            color: $tetriary-color;
        }
    }
}