@import "../../common/colors.scss";
@import "../../common/proportions.scss";

.logo {
background-color: $tetriary-color;
height: 3rem;
width: 3rem;
border-radius: 100%;
margin-right: .4rem;
padding: 1px;
border: 1px solid $font-secondary;
}

.fixed {
    position: fixed !important;
    top: 0;
    width: 100%;
    height: 82px;
    border-bottom: 2px solid $font-secondary;
    z-index: 10;
}

.title {
    margin-left: 10px;;
}
 
.nav-link {
    border-top: .1875rem solid $primary-color;
    letter-spacing: 0.6px;
    &:hover { 
        border-top: .1875rem solid $tetriary-color;
    }

    &:active { 
        border-top: .1875rem solid $tetriary-color;
    }

    &:focus { 
        border-top: .1875rem solid $tetriary-color;
    }
}

.offcanvas.offcanvas-end {
    width: 300px !important;
}

.navbar-toggler {
    border: none !important;
}

.dark {
    background-color: $primary-color !important;
    color: $font-primary !important;
}

.nav-head {
    border-bottom: 2px solid $font-primary;
}

.offcanvas-body {
    text-align: center;
}

.offcanvas-header .btn-close {
    background-color: $font-primary !important;
}